import { useOutletContext, Link } from "react-router-dom";
import { useState, useRef } from "react";

export default function HeroProduct() {
  const productData = useOutletContext();

  const hasVideo = productData.video;
  const hasEbook = productData.ebookThumbnail;

  const [isPlaying, setIsPlaying] = useState(() => false);

  const videoElement = useRef(null);

  return hasVideo || hasEbook ? (
    <article className="flex flex-col gap-6 lg:flex-row">
      {hasVideo && (
        <article className="flex flex-col items-center">
          <div className="relative rounded-md w-full flex flex-col items-center justify-center">
            <div
              onClick={() => playVideo(setIsPlaying, videoElement.current)}
              className={`absolute z-20 top-0 left-0 bottom-0 right-0 h-full w-full cursor-pointer rounded-md ${
                isPlaying && "hidden"
              }`}
            >
              <picture className="rounded-md w-full">
                <img
                  src={productData.thumbnail}
                  alt=""
                  className="w-full h-full rounded-md"
                />
              </picture>

              <i className="icon-play-circle z-10 text-white absolute text-8xl top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"></i>
            </div>

            <video
              ref={videoElement}
              src={productData.video}
              className="rounded-md"
            ></video>
          </div>

          <article className="px-4 py-1 lg:px-0 flex flex-col gap-2 w-full">
            <h2 className="font-bold text-lg md:text-2xl text-primary">
              {productData.title}
            </h2>

            <p className="text-base font-normal text-customBlack-black">
              {productData.content}
            </p>
          </article>
        </article>
      )}
      {hasEbook && (
        <article className="flex flex-col gap-4 px-4 w-full max-w-[26.5rem] m-auto">
          <picture>
            <img
              src={productData.ebookThumbnail}
              alt=""
              className="w-full rounded-md"
            />
          </picture>

          <Link
            className="button bg-primary text-white text-lg"
            to={"./read-ebook"}
          >
            Keep Reading
          </Link>
        </article>
      )}
    </article>
  ) : (
    <article className="px-4">
      <h1 className="text-lg md:text-2xl font-bold text-customBlack-black">
        {productData.title}{" "}
        <mark className="font-bold text-primary bg-transparent">
          {" "}
          Coming Soon for You.{" "}
        </mark>
      </h1>
    </article>
  );
}

function playVideo(setIsPlaying, videoElement) {
  videoElement.play();
  videoElement.controls = true;

  setIsPlaying(() => true);
}

import { Link } from "react-router-dom";

export default function Bonus(props) {
  const bonusDataElements = props.bonusData.map((bonusDataSingle) => {
    return (
      <BonusContainer
        key={bonusDataSingle.id}
        title={bonusDataSingle.title}
        imgUrl={bonusDataSingle.imgUrl}
        sendTo={`/${bonusDataSingle.urlName}`}
      />
    );
  });

  return (
    <section className="px-4 pb-16">
      <article className="w-full flex flex-col items-center justify-center max-w-7xl m-auto">
        <article className="flex flex-col gap-10 items-center">
          <h2 className="font-bold text-3xl md:text-4xl text-center text-customBlack-black max-w-[17ch] md:max-w-full">
            You also get{" "}
            <mark className="bg-transparent text-primary font-bold">
              {" "}
              4 AMAZING BONUSES{" "}
            </mark>{" "}
          </h2>

          <div className="flex flex-wrap justify-center gap-11">
            {bonusDataElements}
          </div>
        </article>
      </article>
    </section>
  );
}

function BonusContainer(props) {
  return (
    <article className="flex flex-col rounded-md w-full max-w-[25rem] shadow-md md:max-w-[249px]">
      <picture className="w-full">
        <img src={props.imgUrl} alt="" className="w-full rounded-t-md" />
      </picture>

      <article className="flex flex-col pt-4 gap-4 justify-between px-4 h-full border-r border-l border-b border-[rgba(0,0,0,0.3)] pb-6">
        <h2 className="font-bold text-customBlack-black text-center text-2xl md:text-lg">
          {props.title}
        </h2>

        <Link
          to={props.sendTo}
          className="button bg-primary text-white text-base"
        >
          GET MY BONUS
        </Link>
      </article>
    </article>
  );
}

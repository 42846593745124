import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import Home from "./home/Home";
import ProductsLayout from "../layouts/ProductsLayout";
import Products from "./products/Products";
import Ebook from "./ebook/Ebook";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<Home />} />
          <Route path=":productName" element={<ProductsLayout />}>
            <Route index element={<Products />} />

            <Route path="read-ebook" element={<Ebook />} />
          </Route>

          <Route
            path="*"
            element={
              <h1 className="text-red-600 font-bold text-3xl text-center">
                404 Page Not Found!
              </h1>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

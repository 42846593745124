export default function Footer() {
  return (
    <footer className="bg-secondary p-4 footer-height flex flex-col items-center justify-center">
      <div>
        <p className="font-medium text-sm text-white text-center">
          Copyright © 2024 | All rights reserved. Official Beer Trick
          Application.
        </p>
      </div>
    </footer>
  );
}
